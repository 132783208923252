import {cutComma} from './_formatter';
import {round} from './_formatter';
import 'jquery.animate-number';

function convertMonth (month) {
    month = month % 12;
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[month]
}

function animateNumber ($el, value, ms) {
    $el.text( value ).animateNumber({
        number: value,
        numberStep: function (now, tween) {
            $(tween.elem).text( round(now) )
        }
    }, ms);
}

function setObjectPropeties ($arr, obj) {
    $arr.each(function (i, el) {
        var name = $(el).attr('id');
        obj[name] = cutComma($(el).val());
    })
}

function calcPercentage(total, part) {
    return Math.round(part / total * 100);
}

export { convertMonth, animateNumber, setObjectPropeties, calcPercentage };
