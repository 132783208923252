import '../libs/Chart.min';
import {round} from '../_formatter';

var Doughnut = function () {};

Doughnut.prototype.init = function () {
    var ctx = document.getElementById("doughnut").getContext('2d');
    this.chart = new Chart(ctx, {
        type: 'doughnut',
        data: {
            // labels: ["Down payment", "Extra payments", "Total principal paid", "Total interest paid"],
            datasets: [{
                // label: '# of Votes',
                data: [],
                backgroundColor: [
                    'rgba(247, 96, 129, 1)',
                    // 'rgba(248, 159, 63, 1)',
                    'rgba(250, 205, 89, 1)',
                    'rgba(93, 192, 193, 1)',
                    'rgba(248, 159, 63, 1)',
                    // 'rgba(76, 161, 243, 1)'
                ]
            }]
        },
        options: {
            cutoutPercentage: 65,
            responsive: true,
            responsiveAnimationDuration: 0,
            scales: {

            },
            animation: {
                animateScale: true,
                animateRotate: true
            },
            tooltips: {
                // tooltip z-index
                // see https://github.com/chartjs/Chart.js/issues/4517
                backgroundColor: 'rgba(255, 255, 255, 1)',
                cornerRadius: 3,
                borderWidth: 1,
                borderColor: 'rgba(0, 0, 0, .7)',
                xPadding: 10,
                yPadding: 10,
                bodyFontColor: '#333',
                callbacks: {
                    label: function(tooltipItem, data) {
                        var datasetIndex = tooltipItem.datasetIndex;
                        var index = tooltipItem.index;
                        var name = data.labels[index];
                        var value = data.datasets[datasetIndex].data[index];

                        return name + ' ' + value + '%';
                    }
                }
            },
            legend: {
                display: false
            }
        }
    });

};

export var doughnut = new Doughnut();