import {setObjectPropeties} from '../_utils';


var Calc = function () {};

Calc.prototype.getFormValues = function (obj) {
    var inputsArr = $('#mg-form input');
    var selectsArr = $('#mg-form select');

    setObjectPropeties(inputsArr, obj);
    setObjectPropeties(selectsArr, obj);
};

Calc.prototype.calcMonthlyPayment = function (interestOnlyMonthsAmount) {
    var interestOnly = interestOnlyMonthsAmount || 0;
    this.debt = this.homeValue - this.downPayment;
    this.rate = this.interestRate / 100 / 12;

    // mortgageTermMonths - this is input value
    var months = this.mortgageTerm * 12;
    months -= interestOnly;
    var pow = Math.pow((1 + this.rate), months);
    this.K = (this.rate * pow) / (pow - 1);
    this.annuent = this.debt * this.K;
};

Calc.prototype.calcBiweeklyPayment = function() {
    this.biRate = this.interestRate / 100 / 26;
    this.biAnnuent = this.debt * this.K / 2;
};


export var calc = new Calc();