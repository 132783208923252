$(document).ready(function () {
    $('#mobileNavToggler').on('click', function () {
        var $mobileNav = $('#mobileNav');
        var $mobileNavToggler = $('#mobileNavToggler');

        if ($mobileNav.hasClass('opened')) {
            $mobileNav.removeClass('opened');
            $mobileNavToggler.removeClass('fix');
        } else {
            $mobileNav.addClass('opened');
            $mobileNavToggler.addClass('fix');
        }
    })
});

