import './libs/cleave.min';

$(document).ready(function () {
    // TODO when focus - select value ?

    $('[data-format="decimal-locate"]').each(function (i, el) {
        new Cleave(el, {
            numeral: true,
            numeralDecimalScale: 2,
            numeralThousandsGroupStyle: 'thousand'
        });
    });

    $('[data-format="decimal"]').each(function (i, el) {
        new Cleave(el, {
            numeral: true,
            numeralDecimalScale: 2,
            numeralThousandsGroupStyle: 'none'
        });
    });

    $('[data-format="decimal3"]').each(function (i, el) {
        new Cleave(el, {
            numeral: true,
            numeralDecimalScale: 3,
            numeralThousandsGroupStyle: 'none'
        });
    });
});

function round(num) {
    var round = Math.round(num * 100) / 100;

    // TODO .toLocaleString() have very long runtime
    // ( ~ 1000ms total then do scheduleMarkup, it's 360 iteration * 5 toLocaleString calls.  IE11 )
    return round.toLocaleString("en", { minimumFractionDigits: 2});
}

function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

function cutComma (str) {
    return Number( str.replace( /,/g, '') )
}

export { round, isNumeric, cutComma }