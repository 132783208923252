import {cutComma} from './_formatter';
import {round} from './_formatter';
import './libs/cleave.min';

var DependentFields = function (el, onValueChangedFoo, numeralThousandsGroupStyle, numeralDecimalScale) {
    this.el = el;
    this.onValueChangedFoo = onValueChangedFoo;
    this.numeralThousandsGroupStyle = numeralThousandsGroupStyle;
    this.loading = true;
    this.numeralDecimalScale = numeralDecimalScale || 2;
};

DependentFields.prototype = {
    init: function () {
        if ( this.checkEl() ) {
            this.initMask()
        }
    },

    checkEl: function () {
        return document.querySelector(this.el)
    },

    initMask: function () {
        var that = this;
        new Cleave(this.el, {
            numeral: true,
            numeralDecimalScale: 3,
            numeralThousandsGroupStyle: this.numeralThousandsGroupStyle,
            onValueChanged: function (e) {
                !that.loading ? that.onValueChangedFoo(e) : that.loading = undefined
            }
        });
    }
};

$(document).ready(function () {

    var fieldsArr = [
        ['#downPayment', '#downPaymentPerc', '#homeValue', 2],
        ['#taxes', '#taxesPerc', '#homeValue', 2],
        ['#insurance', '#insurancePerc', '#homeValue', 2],
        ['#pmi', '#pmiPerc', 'debt', 3]
    ];

    var $homeValue = cutComma( $('#homeValue').val() );
    var $downPayment = $('#downPayment');
    var $downPaymentValue = $downPayment.length ? cutComma( $downPayment.val() ) : 0;

    fieldsArr.forEach(function (el) {
        var sum = el[2] !== 'debt' ? $homeValue : $homeValue - $downPaymentValue;

        new DependentFields(el[0], function (e) {
            var field0Val = cutComma( e.target.value );
            var field1Val = field0Val / sum * 100;
            $(el[1]).val( round(field1Val) );
        }, 'thousand').init();

        new DependentFields(el[1], function (e) {
            var field1Val = e.target.value;
            var field0Val = field1Val * sum / 100;
            $(el[0]).val( round(field0Val) );
        }, 'none').init();

    });

    new DependentFields('#mortgageTerm', function (e) {
        var years = e.target.value;
        $('#mortgageTermMonths').val( Math.round( years * 12 * 100 ) / 100 );
    }, 'none', 0).init();

    new DependentFields('#mortgageTermMonths', function (e) {
        var months = e.target.value;
        $('#mortgageTerm').val( Math.round((months / 12) * 100 ) / 100 );
    }, 'none', 0).init();

});