import {observer} from '../_observer';
import {setObjectPropeties} from '../_utils';

var Extra = function (formOpenBtn) {
    this.formOpenBtn = formOpenBtn;
    this.extraPayment = 0; // TODO
};

Extra.prototype = {
    init: function () {
        var that = this;

        $(this.formOpenBtn).on('click', function () {
            that._showExtra();
        });

        $('#extraPaymentsForm').on('change', function () {
            observer.fireEvent('onExtraValuesChange')
        });
    },

    _showExtra: function () {
        var $box = $('#extraPaymentsContent');
        var $btn = $('#addExtraPayments');

        if ( $box.hasClass('opened') ) {
            $box.removeClass('opened');
            $btn.text('Add extra payments');
            this.extraContainerOpened = false;
        } else {
            $box.addClass('opened');
            $btn.text('Hide extra payments');
            this.extraContainerOpened = true;
        }
    },

    getExtraFormValues: function () {
        var that = this;
        this.extraTotal = 0;

        if ( this.extraContainerOpened ) {
            var inputsArr = $('#extraPaymentsForm input');
            var selectsArr = $('#extraPaymentsForm select');

            setObjectPropeties(inputsArr, this);
            setObjectPropeties(selectsArr, this);
        } else {
            this.extraMonthly = this.extraYeary = this.extraOneTime = 0;
        }
    },

    checkExtra: function(month, year) {
        var extra = this.extraMonthly;

        if (month % 12 === this.extraYearyMonth) {
            extra += this.extraYeary
        }
        if ( (year === this.extraOneTimeYear) && (month % 12 === this.extraOneTimeMonth) ) {
            extra += this.extraOneTime;
        }
        this.extraTotal += extra;
        return extra
    }
};

export var extra = new Extra('#addExtraPayments');