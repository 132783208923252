import '../libs/bootstrap.datepicker.min';
import {observer} from '../_observer';
import {round} from '../_formatter';


var Schedule = function (optionsObj) {
    this.$showScheduleBtn = $(optionsObj.showScheduleBtn);
    this.$schedulePayoffDate = $(optionsObj.schedulePayoffDateBlock);
    this.$scheduleBlock = $(optionsObj.scheduleBlock);
    this.$scheduleTbody = $(optionsObj.scheduleTbody);
    this.$sheduleContainer = $(optionsObj.sheduleContainer);
};

Schedule.prototype = {
    init: function () {
        var that = this;

        $(this.$showScheduleBtn).on('click', function () {
            that._scrollToSchedule();
            that._showHideScheduleContainer();
        });

        this._initScheduleDatepicker();
    },

    _initScheduleDatepicker: function() {
        var that = this;
        // elem must be $('.datepicker'), don't work with another elem
        var $picker = this.dateScheduleDatePicker =  $('.datepicker');
        $picker.datepicker({ assumeNearbyYear: true });
        $picker.on('changeDate', function() {
            var month = that.month;
            var year = that.year;

            that.getDate();
            var newMonthBoolean = (month !== that.month || year !== that.year);
            observer.fireEvent('onStartDayChanged', [newMonthBoolean]);

        });
        $picker.datepicker('update', 'getDate');
        this.getDate();
    },

    getDate: function() {
        var date = this.dateScheduleDatePicker.datepicker('getDate');
        this.day = date.getDate();
        this.month = date.getMonth();
        this.year = date.getFullYear();
    },

    changePayoffDate: function(scheduleArr) {
        this.$schedulePayoffDate.text( this._convertPayoffDate(scheduleArr) );
    },

    _convertPayoffDate: function(scheduleArr) {
        var day = this.day;
        var last = scheduleArr.length - 1;
        var lastMonth =  scheduleArr[last].month;
        var lastYear =  scheduleArr[last].year;
        return lastMonth + ' ' + day + ', ' + lastYear
    },

    _showHideScheduleContainer: function () {
        var $schedule = this.$scheduleBlock;
        if ( $schedule.hasClass('opened') ) {
            // $('#amoSchedule').empty();
            $schedule.removeClass('opened');
            this.$showScheduleBtn.find('span').text('Show');
            this.scheduleContainerOpened = false;
        } else {
            $schedule.addClass('opened');
            this.$showScheduleBtn.find('span').text('Hide');
            this.scheduleContainerOpened = true;
            observer.fireEvent('onScheduleContainerOpen');
        }
    },

    _scrollToSchedule: function() {
        if ( !this.$scheduleBlock.hasClass('opened') ) {
            var wrapperScrolledValue = $('html')[0].scrollTop;
            var scheduleHeightToTop = this.$sheduleContainer[0].getBoundingClientRect().top + wrapperScrolledValue + 50;

            $('html').animate({
                scrollTop: scheduleHeightToTop
            });
        }
    },

    renderScheduleTable: function (scheduleArr) {
        if ( this.$scheduleBlock.hasClass('opened') ) {
            var markup = '';
            this.changePayoffDate(scheduleArr);

            var t0 = performance.now();
            for (var i = 0; i < scheduleArr.length; i++) {
                var el = scheduleArr[i];

                markup +=
                    '<tr>' +
                    '<td class="left ' + i + '">' + el.month + ' ' + el.year + '</td>' +
                    '<td class="td-annuent">$' + round(el.annuent) + '</td>' +
                    '<td class="td-principal">$' + round(el.principal ) + '</td>' +
                    '<td class="td-interest">$' + round(el.interest) + '</td>' +
                    '<td class="td-total-interest">$' + round(el.totalInterest) + '</td>' +
                    '<td class="righ td-balance">$' + round(el.balance) + '</td>' +
                    '</tr>';
            }
            var t1 = performance.now();

            // TODO ~ 2500 ms render in IE11. Need to optimize css styles, mb change html method
            this.$scheduleTbody.html(markup);
            var t2 = performance.now();

            // console.log('doMarkup', t1 - t0);
            // console.log('html', t2 - t1);
        }
    },

    renderBiweeklyScheduleTable: function (ar1) {

        if ( this.$scheduleBlock.hasClass('opened') ) {
            var markup = '';
            var markup2 = '';

            for (var i = 1; i < ar1.length; i++) {
                var biweeklyBalance = ar1[i].biweeklyBalance ? ar1[i].biweeklyBalance.biBalance : 0;
                var monthlyBalance = ar1[i].monthlyBalance ? ar1[i].monthlyBalance.balance : 0;

                markup +=
                    '<tr>' +
                        '<td class="left ' + i + '">' + i + '</td>' +
                        '<td class="righ td-balance">$' +  round( biweeklyBalance ) + '</td>' +
                        '<td class="righ td-balance">$' + round( monthlyBalance ) + '</td>' +
                    '</tr>';
            }


        }
        this.$scheduleTbody.html(markup);
        this.$scheduleTbody.append(markup2)
    }

};

var schedule = new Schedule({
    showScheduleBtn: '#showAmortSchedule',
    schedulePayoffDateBlock: '#schedulePayoffDate',
    scheduleBlock: '.amortization-schedule',
    scheduleTbody: '#amoSchedule',
    sheduleContainer: '#scheduleContainer'
});

export {schedule}

