import {calc} from './calc';


var ShareLink = function (keyword) {
    var that = this;
    this.newReload = true;
    this.keyword = keyword;
    this.hashArr = [];
    this.formValues = {};

    $('#createSharelink').on('click', function () {
        var sharelink = that.createSharelink();
        $('.calc-links__item').css('opacity', 0);
        $('#sharelinkField').addClass('visible').val(sharelink).select();
    });

    $('#copySharelink').on('click', function () {
        console.log('copy to buffer');
    });

    $('body').on('click', function (e) {
        if ( !$(e.target).closest('.calc-links__container').length ) {
            $('#sharelinkField').removeClass('visible');
            $('.calc-links__item').css('opacity', 1);
        }
    });
};

ShareLink.prototype.checkLoadFromHash = function () {
    return this.newReload && location.hash && this.checkKeywordHash();
};

ShareLink.prototype.getHash = function () {
    var hash = location.hash;
    this.hashArr = hash.slice(1).split('&');
};

ShareLink.prototype.getHashKeyValue = function (key) {
    var hash = {};
    var q;

    for (var i = 0; i < this.hashArr.length; i++) {
        if (q = this.hashArr[i].split('='))
            hash[q[0]] = decodeURIComponent(q[1]) || null;
    }
    return key
        ? hash.hasOwnProperty(key) ? hash[key] : null
        : hash;
};

ShareLink.prototype.checkKeywordHash = function () {
    return Array.isArray( location.hash.match(this.keyword) );
};

ShareLink.prototype.setHashValuesToForm = function () {
    var that = this;
    this.newReload = false;
    this.getHash();

    $('#mg-form input').each(function (i, el) {
       var key = $(el).attr('id');
       var replacedKey = that.replaceValue(key);
       var hashKeyValue = that.getHashKeyValue( replacedKey );

       if (hashKeyValue) {
           $(el).val( Number(hashKeyValue).toLocaleString('en') )
       }
    });

};

ShareLink.prototype.createSharelink = function () {
    calc.getFormValues(this.formValues);
    var str = location.origin + location.pathname + '#' + this.keyword + '&';

    for (var key in this.formValues) {
        var letters = '';
        var match = key.match( /[A-Z]/g, );
        for (var i = 0; i < match.length; i++) {
            if (i < 2) {
                letters += match[i]
            }
        }
        str += key[0] + letters.toLowerCase() + '=' + this.formValues[key] + '&'
    }
    if ( str.slice(-1) === '&' ) {
        str = str.slice(0, str.length - 1);
    };
    return str;
};

ShareLink.prototype.replaceShortcutKey = function (key) {
    return this.correspondance[key] || key;
};

ShareLink.prototype.replaceValue = function (key) {

    for (var prop in this.correspondance) {
        if ( this.correspondance[prop] === key ) {
            return prop;
        }
    }
    return key
};

ShareLink.prototype.correspondance =
    {
        hv: 'homeValue',
        dp: 'downPayment',
        dpp: 'downPaymentPerc',
        ir: 'interestRate',
        mt: 'mortgageTerm',
        mtp: 'mortgageTermPerc'
    };



// https://localhost:3000#mtg&homeValue=100000&downPayment=20000&mortgageTerm=30

export var shareLink = new ShareLink('mtg');
