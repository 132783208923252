import './libs/bootstrap.dropdown';
import './libs/bootstrap.tooltip';
import {observer} from './_observer';
import {calc} from './modules/calc';
import {extra} from './modules/extra';
import {schedule} from './modules/schedule';
import {doughnut} from './modules/doughnut';
import {baseCalculator} from './common';
import {shareLink} from './modules/shareLink';
import {round} from './_formatter';
import {convertMonth} from './_utils';
import {animateNumber} from './_utils';
import {calcPercentage} from './_utils';
import './dependent-fields';
import './common';
import './mobileNav';


// var extra = new Extra('#addExtraPayments');

$(document).ready(function () {
    new CalcMortgage( $('#calculateBtn') );
});

var CalcMortgage = function ($btn) {
    this.$calculateBtn = $btn;
    this.init();
};

CalcMortgage.prototype = {
    init: function () {
        var that = this;
        extra.init();
        schedule.init();
        doughnut.init();

        that.calculateAndDraw();

        this.$calculateBtn.on('click', function () {
            that.calculateAndDraw();
        });

        observer.addListener('onStartDayChanged', function (newMonthBoolean) {
            if (newMonthBoolean) {
                that.doScheduleArr();
                schedule.renderScheduleTable(that.scheduleArr);
            } else {
                schedule.changePayoffDate(that.scheduleArr);
            }
        });

        observer.addListener('onScheduleContainerOpen', function () {
            var t0 = performance.now();
            that.doScheduleArr();
            var t1 = performance.now();
            console.log('doScheduleArr', t1 - t0);

            schedule.renderScheduleTable(that.scheduleArr);
        });

        observer.addListener('onExtraValuesChange', function () {
            that.doCalculations();
            that.fillResultTable();
            that.updateDoughnutChart();
            schedule.renderScheduleTable(that.scheduleArr);
        });

        $('#downloadPdf').on('click', function () {
            baseCalculator.download(that.scheduleArr);
        });

        if ( shareLink.checkLoadFromHash() ) {
            shareLink.setHashValuesToForm();
            this.calculateAndDraw();
        } else {
            this.doCalculations();
            this.updateDoughnutChart([ 13, 0, 51, 37 ]);
        }
    },

    calculateAndDraw: function () {
        this.doCalculations();
        this.fillResultTable();
        this.updateDoughnutChart();
        if (schedule.scheduleContainerOpened) {
            schedule.renderScheduleTable(this.scheduleArr);
        }
    },

    doCalculations: function () {
        calc.getFormValues(calc);
        calc.calcMonthlyPayment();
        extra.getExtraFormValues();
        this.doScheduleArr();
    },

    doScheduleArr: function() {
        this.scheduleArr = [];
        var balance = calc.debt;
        var totalInterest = 0;
        var month = schedule.month + 1;
        var year = schedule.year;

        for (var i = 0; i < calc.mortgageTermMonths; i++) {
            var m = convertMonth(month);
            var extraPayment = extra.extraContainerOpened ? extra.checkExtra(month, year) : 0;
            var annuent = calc.annuent + extraPayment;
            var interestPayment = balance * calc.rate;
            var principalPayment = annuent - interestPayment ;

            balance -= principalPayment;
            totalInterest += interestPayment;
            this.scheduleArr.push(
                {
                    month: m,
                    year: year,
                    annuent: annuent,
                    principal: principalPayment,
                    interest: interestPayment,
                    totalInterest: totalInterest,
                    balance: balance
                }
            );
            if (balance < 0) {
                var prevBalanceValue = this.scheduleArr[i - 1].balance;
                var interest = this.scheduleArr[i].interest = prevBalanceValue * calc.rate;
                var principal = this.scheduleArr[i].principal = prevBalanceValue;
                this.scheduleArr[i].annuent = interest + principal;
                this.scheduleArr[i].balance = 0;

                break
            }
            month += 1;
            if (m === 'Dec') {
                year += 1
            }
        }
        calc.totalInterest = totalInterest;
    },

    fillResultTable: function() {
        var totalMonthPayment = round(calc.annuent + extra.extraMonthly);

        $('#resMonthlyPrincipalAndInterest').text( round(calc.annuent) );
        $('#resMonthlyExtraPayment').text( round(extra.extraMonthly) );
        $('#totalMounthlyPayment').text(totalMonthPayment);

        $('#resDownPayment').text( round(calc.downPayment) );
        $('#resExtraPayments').text( round(extra.extraTotal) );
        $('#totalPrincipalPaid').text( round(calc.debt - extra.extraTotal) );
        $('#totalInterestPaid').text( round(calc.totalInterest) );

        var totalPayment = calc.downPayment + (calc.debt - extra.extraTotal) + extra.extraTotal + calc.totalInterest;
        var monthlyPayment = calc.annuent + extra.extraMonthly;
        animateNumber($('#totalMonthlyPayment'), monthlyPayment, 300);
        animateNumber($('#totalPayment'), totalPayment, 300)
    },

    updateDoughnutChart: function(dataArr) {
        var chart = doughnut.chart;
        var totalPayment = calc.downPayment + extra.extraTotal + (calc.debt - extra.extraTotal) + calc.totalInterest;
        var d = calcPercentage(totalPayment, calc.downPayment);
        var ex = calcPercentage(totalPayment, extra.extraTotal);
        var debt = calcPercentage(totalPayment, calc.debt - extra.extraTotal);
        var int = calcPercentage(totalPayment, calc.totalInterest);
        var data = dataArr || [d, ex, debt, int];

        chart.data.datasets[0].data = data;
        chart.data.labels = ["Down payment", "Extra payments", "Total principal paid", "Total interest paid"];
        chart.update();
    }
};

// jQuery.extend(mortCalc, baseCalculator);

/*

doAnnualCalculation: function() {
    var p = 0;
    var i = 0;
    this.principalArr = [];
    this.interestArr = [];
    this.balanceArr = [];

    for (var k = 0; k < this.scheduleArr.length; k++) {
        var el = this.scheduleArr[k];
        p += el.principal;
        i += el.interest;

        if ( (k+1) % 12 === 0) {
            this.principalArr.push(p);
            this.interestArr.push(i);
            this.balanceArr.push(el.balance);
            p = i = b = 0;
        }
    }
},

updateBarChart: function () {
    this.doAnnualCalculation();
    var chart = barChart.chart;
    // TODO find another solution for max value axisY
    var max0 = this.debt + this.debt * 0.3;
    var max1 = this.annuent*12 + this.annuent*12 * 0.1  ;

    chart.options.scales.yAxes[0].ticks = {min: 0, max: max0 };
    chart.options.scales.yAxes[1].ticks = {min: 0, max: max1 };
    chart.data.datasets[2].data = this.balanceArr;
    chart.data.datasets[0].data = this.principalArr;
    chart.data.datasets[1].data = this.interestArr;

    // TODO Array(30) <-> years
    chart.data.labels = Array(30);
    chart.update();
},

*/

