import {round} from "./_formatter";
import {calc} from "./modules/calc";


$(document).ready(function () {

    var $tooltipToggler = $('[data-toggle="tooltip"]');
    var $parentTooltip = $tooltipToggler.parent();
    var tooltipTimerId;
    var tooltipTimerId2;

    $tooltipToggler.tooltip({
        trigger: 'manual'
    });

    $tooltipToggler.on('mouseenter click', function () {
        var that = this;

        clearTimeout(tooltipTimerId);
        tooltipTimerId2 = setTimeout(function () {
            $(that).tooltip('show');
        }, 150);
    });

    $parentTooltip.on('mouseenter', '.tooltip', function () {
        clearTimeout(tooltipTimerId);
    });

    $tooltipToggler.on('mouseleave', function () {
        clearTimeout(tooltipTimerId2);
        tooltipTimerId = setTimeout(function () {
            $tooltipToggler.tooltip('hide');
        }, 200);
    });

    $parentTooltip.on('mouseleave', '.tooltip', function () {
        tooltipTimerId = setTimeout(function () {
            $tooltipToggler.tooltip('hide');
        }, 200);
    });

});

var baseCalculator = (function () {
    return {

        download: function (scheduleArr) {
            
            var result = {};
            result.data = [];
            
            for (var i = 0; i < scheduleArr.length; i++) {
                var el = scheduleArr[i];
                var annuent = '$' + round(el.annuent);
                var principal = '$' + round(el.principal );
                var interest = '$' + round(el.interest );
                var totalInterest = '$' + round(el.totalInterest );
                var balance = '$' + round(el.balance );
                var date = el.month + ' ' + el.year;
                
                var row = {
                        date: date,
                        annuent: annuent,
                        principal: principal,
                        interest: interest,
                        totalInterest: totalInterest,
                        balance: balance
                };
                result.data.push(row);
            }

            result.homeValue = '$' + round(calc.homeValue);
            result.downPayment = '$' + round(calc.downPayment);
            result.term = '$' + round(calc.mortgageTerm);
            result.interestRate = '$' + round(calc.interestRate);

            $.ajax({
                type: "POST",
                url: '/generate-schedule',
                data: {
                    data: JSON.stringify(result)
                },
                success: function (resp) {
                    if (resp && resp.id) {
                        window.location.replace('/dl?id=' + resp.id);
                    }                    
                },
                dataType: 'json'
              });
        }
    }
})();

export {baseCalculator}