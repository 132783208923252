var observer = (function() {
    var listeners = [];
    return {
        addListener : function(eventName, listener) {
            if (!listeners[eventName]) {
                listeners[eventName] = [];
            }

            listeners[eventName].push(listener);
        },
        fireEvent : function(eventName, args) {
            // console.info(eventName);
            if (!listeners[eventName]) {
                return;
            }
            for (var i = 0; i < listeners[eventName].length; i++) {
                listeners[eventName][i].apply(this, args);
            }
        }
    };
})();

/*
 *  For build widgetPage by GCC comment export
 *  For build calculators page uncomment export and run webpack
 */
export {observer}